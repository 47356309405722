<form role="form" #form="ngForm">
  <div class="drawer-header position-relative d-flex align-items-center justify-content-between p-4">
    <h4 class="drawer-title">{{ 'Filter' | i18next }}</h4>
    <div class="d-flex justify-content-end align-items-center">
      <button mat-stroked-button (click)="reset()" color="secondary" class="medium clear-filter-btn" [class.hide]="checkButtonDisabled()">
        {{ 'Clear filter' | i18next }}
      </button>
      <mat-icon class="drawer-close-icon pointer" (click)="navigateToList()">close</mat-icon>
    </div>
  </div>

  <div class="drawer-details px-4 pb-4" id="clientsListFilter">
    <div class="inner-client">
      <div class="inner-demographics">
        <div>
          <label class="form-label">Regions</label>
          <app-con-filter-select
            [loading]="basicFormValues?.loading"
            [type]="2"
            [items]="demographyList.regionList"
            [markedItems]="clientsListFilter.region"
            [isSelectMandatory]="false"
            matSelectPlaceHolder="Select Regions"
            matInputPlaceHolder="Search Regions"
            (selectComplete)="selectComplete($event)"
          >
          </app-con-filter-select>
        </div>

        <div>
          <label class="form-label">Countries</label>
          <app-country-select
            [loading]="basicFormValues?.loading"
            [type]="3"
            [items]="filteredCountryList"
            [markedItems]="clientsListFilter.country"
            [isSelectMandatory]="false"
            matSelectPlaceHolder="Select Countries"
            matInputPlaceHolder="Search Countries"
            (selectComplete)="selectComplete($event)"
          >
          </app-country-select>
        </div>

        <ng-container *ngIf="industryCapabilitySingleLevel; else multiLevel">
          <div>
            <label class="form-label">Industries</label>
            <app-con-filter-select
              [loading]="basicFormValues?.loading"
              [type]="1"
              [items]="demographyList.industryList"
              [markedItems]="clientsListFilter.industry"
              [isSelectMandatory]="false"
              matSelectPlaceHolder="Select Industries"
              matInputPlaceHolder="Search Industries"
              (selectComplete)="selectComplete($event)"
            >
            </app-con-filter-select>
          </div>

          <div>
            <label class="form-label">Capabilities</label>
            <app-con-filter-select
              [loading]="basicFormValues?.loading"
              [type]="4"
              [items]="demographyList.capabilityList"
              [markedItems]="clientsListFilter.capability"
              [isSelectMandatory]="false"
              matSelectPlaceHolder="Select Capabilities"
              matInputPlaceHolder="Search Capabilities"
              (selectComplete)="selectComplete($event)"
            >
            </app-con-filter-select>
          </div>
        </ng-container>
        <ng-template #multiLevel>
          <div>
            <label class="form-label">Industries</label>
            <app-con-filter-select
              [loading]="basicFormValues?.loading"
              [type]="1"
              [items]="demographyList.industryList"
              [markedItems]="clientsListFilter.industry"
              [isSelectMandatory]="false"
              matSelectPlaceHolder="Select Industries"
              matInputPlaceHolder="Search Industries"
              (selectComplete)="selectComplete($event)"
            >
            </app-con-filter-select>
          </div>
          <ng-container *ngIf="clientsListFilter?.industry?.length > 0 && industryChildList?.length > 0">
            <div>
              <label class="form-label">{{ 'Sub Industries' | i18next }}</label>
              <app-con-filter-select
                [loading]="basicFormValues?.loading"
                [type]="1"
                [items]="industryChildList"
                [markedItems]="clientsListFilter.industry"
                [isSelectMandatory]="false"
                matSelectPlaceHolder="{{ 'Select Sub Industries' | i18next }}"
                matInputPlaceHolder="{{ 'Search Sub Industries' | i18next }}"
                (selectComplete)="selectComplete($event)"
              >
              </app-con-filter-select>
            </div>
          </ng-container>

          <div>
            <label class="form-label">Capabilities</label>
            <app-con-filter-select
              [loading]="basicFormValues?.loading"
              [type]="4"
              [items]="demographyList.capabilityList"
              [markedItems]="clientsListFilter.capability"
              [isSelectMandatory]="false"
              matSelectPlaceHolder="Select Capabilities"
              matInputPlaceHolder="Search Capabilities"
              (selectComplete)="selectComplete($event)"
            >
            </app-con-filter-select>
          </div>

          <ng-container *ngIf="clientsListFilter?.capability?.length > 0 && capabilityChildList?.length > 0">
            <div>
              <label class="form-label">{{ 'Sub Capabilities' | i18next }}</label>
              <app-con-filter-select
                [loading]="basicFormValues?.loading"
                [type]="4"
                [items]="capabilityChildList"
                [markedItems]="clientsListFilter.capability"
                [isSelectMandatory]="false"
                matSelectPlaceHolder="{{ 'Select Sub Capabilities' | i18next }}"
                matInputPlaceHolder="{{ 'Search Sub Capabilities' | i18next }}"
                (selectComplete)="selectComplete($event)"
              >
              </app-con-filter-select>
            </div>
          </ng-container>
        </ng-template>
        <div *ngIf="companySizeFilterEnabled">
          <div>
            <label class="form-label">Company Size</label>
            <mat-form-field class="full-width mb-2" floatLabel="never" [ngClass]="{ 'skeleton-loader skeleton-title w-100 h-auto': basicFormValues?.loading }">
              <mat-select placeholder="Select Company Size" [(ngModel)]="clientsListFilter.companySize" name="companySizeFilter" color="primary">
                <mat-option class="customize-options" *ngFor="let item of MarketExplorationCompanySizeFilterValues" [value]="item">{{ item }} </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div *ngIf="improveoDiversityFilterEnabled">
          <div>
            <label class="form-label">{{ 'Diversity' | i18next }}</label>
            <app-con-filter-select
              [loading]="basicFormValues?.loading"
              [type]="5"
              [items]="ImproveoCompanyDiversity"
              [markedItems]="clientsListFilter.improveoDiversity"
              [isSelectMandatory]="false"
              matSelectPlaceHolder="{{ 'Select Diversity' | i18next }}"
              matInputPlaceHolder="{{ 'Search Diversity' | i18next }}"
              (selectComplete)="selectComplete($event)"
            >
            </app-con-filter-select>
          </div>
        </div>
        <div *ngIf="improveoCommunityImpactFilterEnabled">
          <div>
            <label class="form-label">{{ 'Community Impact' | i18next }}</label>
            <app-con-filter-select
              [loading]="basicFormValues?.loading"
              [type]="6"
              [items]="ImproveoCompanyCommunityImpactList"
              [markedItems]="clientsListFilter.improveoCommunityImpact"
              [isSelectMandatory]="false"
              matSelectPlaceHolder="{{ 'Select Community Impact' | i18next }}"
              matInputPlaceHolder="{{ 'Search Community Impact' | i18next }}"
              (selectComplete)="selectComplete($event)"
            >
            </app-con-filter-select>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="drawer-footer d-flex align-items-center justify-content-start p-4">
    <button class="mr-12" color="accent" mat-stroked-button (click)="navigateToList()" type="button" back-button>Cancel</button>
    <button color="primary" type="button" mat-flat-button [disabled]="disableFilter()" [hidden]="basicFormValues?.loading" (click)="onFormSubmit()">
      Apply
    </button>
    <button color="primary" type="button" mat-flat-button [disabled]="basicFormValues.loading" [hidden]="!basicFormValues?.loading">Loading...</button>
  </div>
</form>
