import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { I18NextModule } from 'angular-i18next';
import { CustomisationDynamicTemplateComponent } from './customisation-dynamic-template.component';
import { ConpulseModule } from '../../conpulse.module';
import { QuillModule } from 'ngx-quill';
import { ConpulseMaterialModule } from '../../material-module';
import { FormsModule } from '@angular/forms';
import { UtilityMethodsService } from '../../services';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FileInputModule } from '../file-input/file-input.module';

@NgModule({
  declarations: [CustomisationDynamicTemplateComponent],
  imports: [CommonModule, ConpulseModule, I18NextModule, QuillModule.forRoot(), ConpulseMaterialModule, FormsModule, I18NextModule, DragDropModule, FileInputModule],
  exports: [CustomisationDynamicTemplateComponent],
})
export class CustomisationDynamicTemplateModule {
  constructor(private readonly utilityMethod: UtilityMethodsService) {
    this.utilityMethod.prepareQuillModule();
  }
}
