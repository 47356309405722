import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UtilityMethodsService } from '../../services';
import { DOCUMENT_TYPE, NotificationService, WebsiteRegex, fileInputDocument } from '@conpulse-web/core';

@Component({
  selector: 'conpulse-web-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
})
export class FileInputComponent implements OnInit {
  driveLink: string = '';
  @Input() onlyDocumentUpload: boolean = false;
  @Input() set accept(allowedFormat: string[]) {
    this.allowedFormats = allowedFormat;
    this.formatDisplayText = this.allowedFormats.reduce((acc: Array<string>, format: string) => {
      acc.push(format.substring(1));
      return acc;
    }, []);
  }
  @Input() fileLimit: [] = [];
  @Input() set document(value: fileInputDocument[]) {
    value?.forEach((doc) => {
      if (doc?.type === DOCUMENT_TYPE.DOCUMENT && !doc?.file) doc['isUploaded'] = true;
    });
    this.documents = value;
  }
  @Input() showUploadSection = true;
  @Input() maxCount: number = 2;
  @Input() disabled: boolean = false;
  @Input() showDelete = true;
  @Input() showDocViewBtn = false;
  @Input() multiple: boolean = false;
  @Input() cardMaxWithSelector: string = 'mw-300';
  @Output() download: EventEmitter<{ key: string, name: string }> = new EventEmitter();
  @Output() view: EventEmitter<string> = new EventEmitter();
  @Output() docUploaded: EventEmitter<fileInputDocument[]> = new EventEmitter();
  @ViewChild('fileUpload') fileInput: ElementRef;
  documents: fileInputDocument[] = [];
  formatDisplayText: string[];
  allowedFormats: string[] = [];
  isLinkAlreadyExists: boolean = false;
  websiteRegex = WebsiteRegex;
  constructor(private utilityService: UtilityMethodsService, private notificationService: NotificationService) { }

  ngOnInit(): void { }

  onFileInput(event: InputEvent) {
    const inputElement = event.target as HTMLInputElement;
    const fileList: FileList | null = inputElement.files;
    if (fileList.length + this.documents.length > this.maxCount) {
      this.notificationService.openErrorSnackBar(`You are only allowed to upload a maximum of ${this.maxCount} ${this.maxCount > 1 ? 'files' : 'file'}`);
      return;
    }
    if (fileList?.length) {
      this.onFileUpload(fileList);
    }
  }

  onFileUpload(files: FileList) {
    for (let index = 0; index < files.length; index++) {
      const fileName = files[index].name;
      const fileSize = files[index].size;
      const fileExtension = files[index].name.substring(files[index].name.lastIndexOf('.'), files[index].name.length);
      const fileSupported = this.allowedFormats.includes(fileExtension);
      const maxFileSize = this.utilityService.findMaxFileSize(this.fileLimit, fileExtension);
      if (!fileSupported) {
        this.notificationService.openErrorSnackBar(`Skipping ${fileName} since it is not of allowed file formats`);
        return;
      } else if (files[index].size > maxFileSize * Math.pow(10, 6)) {
        this.notificationService.openErrorSnackBar(`Total size of the file ${files[index].name} should be less than ${maxFileSize} MB`);
        return;
      } else if (this.documents.some((file) => file.name === files[index].name)) {
        this.notificationService.openErrorSnackBar(`${files[index].name} Already exists`);
        return;
      } else {
        this.documents.push({ name: files[index].name, size: fileSize, file: files[index], type: DOCUMENT_TYPE.DOCUMENT, isUploaded: false });
        this.updateParent();
      }
    }
  }

  addLink() {
    this.documents.push({ key: this.driveLink, type: DOCUMENT_TYPE.LINK, isUploaded: false });
    this.updateParent();
    this.updateLinkAlreadyExists();
    this.driveLink = '';
  }

  onRemoveDocument(index: number) {
    if (this.fileInput) this.fileInput.nativeElement.value = '';
    this.documents.splice(index, 1);
    this.updateParent();
  }

  updateParent() {
    this.docUploaded.emit(this.documents);
  }

  updateLinkAlreadyExists() {
    this.isLinkAlreadyExists = this.documents.some(({ key, type }) => key === this.driveLink && type === DOCUMENT_TYPE.LINK);
  }

  onDownload(document: fileInputDocument) {
    if (document?.key?.length) {
      document.type === DOCUMENT_TYPE.LINK ? window.open(document.key) : this.download.emit({ key: document.key, name: document.name });
    }
  }

  viewDoc(document: fileInputDocument) {
    if (document?.key?.length) {
      document.type === DOCUMENT_TYPE.LINK ? window.open(document.key) : this.view.emit(document.key);
    }
  }
}
