import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  DOCUMENT_TYPE,
  DynamicFieldDataTypes,
  DynamicFormsValue,
  MsaDynamicTabKey,
  NotificationService,
  TemplateDocType,
  UploadFileDetails,
  UploadType,
  UploaderService,
  UserService,
  rfpDocumentAllowedFileTypes,
  ContentStyle,
  WebsiteRegex,
  DeleteConfirmationComponent,
  ViewType,
} from '@conpulse-web/core';
import { cloneDeep, isEmpty, trim, get } from 'lodash-es';
import { CommonService, UtilityMethodsService } from '../../services';
import { v4 as uuidv4 } from 'uuid';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'conpulse-web-customisation-dynamic-template',
  templateUrl: './customisation-dynamic-template.component.html',
  styleUrls: ['./customisation-dynamic-template.component.scss'],
})
export class CustomisationDynamicTemplateComponent implements OnInit {
  displayList: DynamicFormsValue[] = [];
  initialDisplayLists: DynamicFormsValue[] = [];
  fileList = [];
  formatList = [];
  @Input() set dynamicFormLists(values: DynamicFormsValue[]) {
    !isEmpty(values) ? this.constructValuesWithDoc(values) : this.displayList = [];
    this.initialDisplayLists = !isEmpty(values) ? cloneDeep(this.displayList) : [];
  }
  // TODO:- Remove commented variables here after v1.30
  // uploadDocumentOrLink: TemplateDocType[] = [];
  // templateDocument: TemplateDocType[] = [];
  @Input() viewList: Array<boolean> = [];
  @Input() tabName;
  @Input() sectionTitle: string = '';
  @Output() onAddGeneralProvisions: EventEmitter<{
    added: DynamicFormsValue[];
  }> = new EventEmitter();
  RfpQuillConfig;
  trim = trim;
  appendix = 'Appendix';
  websiteRegex = WebsiteRegex;
  MSAFieldType = DynamicFieldDataTypes;
  documentDetails = { size: 10 * 1024 * 1024, allowedFiles: '' };
  loggedInUserId = '';
  contentStyle: ContentStyle = { fontStyle: '', fontSize: '', fontFamily: '', color: '' };

  constructor(
    private readonly dialog: MatDialog,
    private userService: UserService,
    private commonService: CommonService,
    private readonly utilityService: UtilityMethodsService,
    private readonly notificationService: NotificationService,
    private readonly uploaderService: UploaderService
  ) {
    this.RfpQuillConfig = this.utilityService.getQuillConfig(this.contentStyle);
  }

  ngOnInit(): void {
    this.loggedInUserId = this.userService.currentUserInformation._id;
    this.loadFileFormats();
  }

  constructValuesWithDoc(value) {
    this.displayList = value.map((data) => {
      if (data.type === 'upload') {
        return {
          ...data,
          selectedDoc: !isEmpty(data?.commonData?.upload?.type === DOCUMENT_TYPE.DOCUMENT ? data?.commonData?.upload?.name : data?.commonData?.upload?.key) ? [{ ...data.commonData.upload, isUploaded: data?.commonData?.upload?.type === DOCUMENT_TYPE.DOCUMENT ? data.commonData.upload?.isNewUpload || false : true }] : [],
        };
      } else if (data.type === 'template') {
        return {
          ...data,
          selectedDoc: !isEmpty(data?.commonData?.upload?.type === DOCUMENT_TYPE.DOCUMENT ? data?.commonData?.upload?.name : data?.commonData?.template.templateReq?.key) ? [{ ...data?.commonData?.template?.templateReq, isUploaded: data?.commonData?.upload?.type === DOCUMENT_TYPE.DOCUMENT ? data?.commonData?.template?.templateReq?.isNewUpload || false: true }] : [],
        };
      } else {
        return data
      }
    });
  }

  /**
   * Loads files fornats list
   */
  async loadFileFormats() {
    const data = await this.utilityService.loadAllowedFileFormats();
    this.fileList = data.fileList;
    this.formatList = data.formatList;
    this.documentDetails.allowedFiles = data.allowedFiles;
  }

  /**
   * Adds new section on required position
   * @param index Index ranges (0 - displayList.length -1)
   */
  addNewField(index: number, type?: DynamicFieldDataTypes) {
    switch (type) {
      case DynamicFieldDataTypes.UPLOAD:
        this.displayList.splice(index, 0, {
          isActive: true,
          commonData: {
            upload: { name: '', key: '' },
          },
          selectedDoc: [],
          type: DynamicFieldDataTypes.UPLOAD,
          title: '',
          id: `${uuidv4()}`,
        });
        break;
      case DynamicFieldDataTypes.TEMPLATE:
        this.displayList.splice(index, 0, {
          isActive: true,
          commonData: {
            template: {
              templateReq: { name: '', key: '' },
              templateRes: { name: '', key: '' },
            },
          },
          selectedDoc: [],
          type: DynamicFieldDataTypes.TEMPLATE,
          title: '',
          id: `${uuidv4()}`,
        });
        break;
      default:
        this.displayList.splice(index, 0, {
          isActive: true,
          commonData: {
            text: '',
          },
          type: DynamicFieldDataTypes.QUILL,
          title: '',
          id: `${uuidv4()}`,
        });
        break;
    }

    this.viewList.splice(index, 0, false);
  }

  /**
   * Updates edited status of the section from the initial value
   * @param index Index ranges (0 - displayList.length -1)
   */
  onValueChanges(index: number) {
    if (this.displayList[index]?.title?.trim()?.length) this.saveSectionDetails();
  }

  /**
   * On Delete of section
   * @param indexToDelete Index ranges (0 - displayList.length -1)
   */
  onDelete(indexToDelete: number) {
    const dialogRef = this.dialog.open(DeleteConfirmationComponent, {
      disableClose: true,
      width: '450px',
    });
    dialogRef.componentInstance.title = 'Delete';
    dialogRef.componentInstance.message = `Are you sure you want to delete the field ${this.displayList[indexToDelete]?.title || ``}?`;
    dialogRef.componentInstance.acceptanceText = 'Delete';
    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.displayList.splice(indexToDelete, 1);
        this.viewList.splice(indexToDelete, 1);
        this.saveSectionDetails();
      }
    });
  }

  /**
   * trackByFn for primitive type loop
   */
  trackByFn(index: number) {
    return index;
  }

  /**
   * Drag/drop event for section
   * @param event DragDrop event
   */
  dropSection(event: CdkDragDrop<string[]>) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.displayList, event.previousIndex, event.currentIndex);
      this.saveSectionDetails();
    }
  }

  /**
   * Saves newly added and edited values
   */
  async saveSectionDetails() {
    this.onAddGeneralProvisions.emit({
      added: [...this.displayList],
    });
  }

  /**
   * Add links to the existing array
   */
  addLink(event, position, type: DynamicFieldDataTypes, isTemplateRes = false) {
    if (type === DynamicFieldDataTypes.TEMPLATE) {
      const data = {
        file: null,
        type: DOCUMENT_TYPE.LINK,
        name: '',
        isNewUpload: false,
        key: event.target?.value?.trim(),
        index: position,
        isTemplateRes: isTemplateRes,
      };
      if (event.target?.value?.trim().length) {
        // this.templateDocument.push(data);
        this.displayList[position].commonData.template = {
          templateReq: !isTemplateRes
            ? { name: '', key: event.target?.value, type: DOCUMENT_TYPE.LINK, createdBy: this.loggedInUserId }
            : { name: '', key: '' },
          templateRes: isTemplateRes ? { name: '', key: event.target?.value, type: DOCUMENT_TYPE.LINK, createdBy: this.loggedInUserId } : { name: '', key: '' },
        };
      }
    } else {
      const data = {
        file: null,
        name: '',
        isUploaded: false,
        index: position,
        key: event.target?.value,
        isNewUpload: false,
        type: DOCUMENT_TYPE.LINK,
      };
      // this.uploadDocumentOrLink.push(data);
      this.displayList[position].commonData.upload = { name: '', key: event.target?.value, type: DOCUMENT_TYPE.LINK, createdBy: this.loggedInUserId };
    }
    event.target.value = null;
    this.saveSectionDetails();
    return;
  }

  /**
   * Event handler for adding associated documents
   * @param event Event from file input element
   */
  uploadDocument(event, position, type: DynamicFieldDataTypes, isTemplateRes = false) {
    if (event?.length) {
      event.forEach((file) => {
        if (type === DynamicFieldDataTypes.TEMPLATE) {
          this.displayList[position].selectedDoc = event;
          this.displayList[position].commonData.template = {
            templateReq: !isTemplateRes
              ? { name: file.name, key: file?.key || '', type: file.type, createdBy: this.loggedInUserId, file: file?.file, isNewUpload: file.type === DOCUMENT_TYPE.DOCUMENT }
              : { name: '', key: file?.key || '' },
            templateRes: isTemplateRes
              ? { name: file.name, key: file?.key || '', type: file.type, createdBy: this.loggedInUserId, file: file?.file, isNewUpload: file.type === DOCUMENT_TYPE.DOCUMENT }
              : { name: '', key: file?.key || '' },
          };
        } else {
          this.displayList[position].selectedDoc = event;
          this.displayList[position].commonData.upload = {
            name: file.name,
            key: file?.key || '',
            type: file.type,
            createdBy: this.loggedInUserId,
            file: file?.file,
            isNewUpload: file.type === DOCUMENT_TYPE.DOCUMENT,
          };
        }
      });
    } else {
      if (type === DynamicFieldDataTypes.TEMPLATE) {
        this.displayList[position].commonData = {
          template: {
            templateReq: { name: '', key: '' },
            templateRes: { name: '', key: '' },
          },
        }
      } else {
        this.displayList[position].commonData = {
          upload: { name: '', key: '' },
        }
      }
    }
    this.displayList = [...this.displayList]
    this.saveSectionDetails();
  }

  // async downloadDocument(fileKey: string) {
  //   try {
  //     const path = `contracts/msa`;
  //     const response = await this.uploaderService.getFileViewUrl({ folderPath: path, fileKey }).toPromise();
  //     window.open(response?.data?.viewUrl);
  //   } catch (error) {
  //     this.notificationService.openErrorSnackBar(`Couldn't download the document`);
  //   }
  // }

  // viewDocument(fileKey: string){
  //   this.uploaderService.openDocument({ folderPath: `contracts/msa/${fileKey}`})
  // }

  async downloadDocument({ key, name }) {
    try {
      const response = await this.uploaderService.getViewUrl({ responseType: ViewType.DOWNLOAD, fileKey: key, fileName: name }).toPromise();
      window.open(response?.data?.viewUrl)
    } catch (error) {
      this.notificationService.openErrorSnackBar(`Couldn't download the document`);
    }
  }

  async viewDocument(fileKey: string) {
    try {
      const response = await this.uploaderService.getViewUrl({ responseType: ViewType.VIEW, fileKey }).toPromise();
      window.open(response?.data?.viewUrl);
    } catch (error) {
      this.notificationService.openErrorSnackBar(`Couldn't download the document`);
    }
  }

  removeDocument(documentIndex: number, type: DynamicFieldDataTypes, isTemplateRes = false) {
    if (type === DynamicFieldDataTypes.TEMPLATE) {
      isTemplateRes
        ? (this.displayList[documentIndex].commonData.template.templateRes = { name: '', key: '' })
        : (this.displayList[documentIndex].commonData.template.templateReq = { name: '', key: '' });
      // const findIndex = this.templateDocument.findIndex((doc) => doc.index === documentIndex);
      // this.templateDocument.splice(findIndex, 1);
    } else {
      this.displayList[documentIndex].commonData.upload = { name: '', key: '' };
      // const findIndex = this.uploadDocumentOrLink.findIndex((doc) => doc.index === documentIndex);
      // this.uploadDocumentOrLink.splice(findIndex, 1);
    }
    this.saveSectionDetails();
  }
}
