import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SpecialDiffType } from '@conpulse-web/core';
import * as htmldiff from '../../services/htmldiff.js';

@Component({
  selector: 'conpulse-web-special-diff',
  templateUrl: './special-diff.component.html',
  styleUrls: ['./special-diff.component.scss'],
})
export class SpecialDiffComponent implements OnChanges {
  @Input() type: SpecialDiffType = SpecialDiffType.DEFAULT;
  @Input() set leftHandSide(value: string) {
    this.leftSideText = value || '';
    this.computeDiff();
  }
  @Input() set rightHandSide(value: string) {
    this.rightSideText = value || '';
    this.computeDiff();
  }
  private leftSideText = '';
  private rightSideText = '';
  diffHtml: string = '';

  ngOnChanges(changes: SimpleChanges): void {
    this.computeDiff();
  }

  /**
   * Computes diff between left hand and right hand side
   */
  computeDiff() {
    if (this.leftSideText !== this.rightSideText) {
      switch (this.type) {
        case SpecialDiffType.DEFAULT:
          this.diffHtml = `<del>${this.leftSideText}</del><ins>${this.rightSideText}</ins>`;
          break;
        case SpecialDiffType.EXTERNAL:
          this.diffHtml = htmldiff(this.leftSideText, this.rightSideText);
          break;
        default:
          this.diffHtml = `<del>${this.leftSideText}</del><ins>${this.rightSideText}</ins>`;
          break;
      }
    } else {
      this.diffHtml = this.leftSideText;
    }
  }
}
