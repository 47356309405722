import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommonAppendixComponent } from './common-appendix.component';
import { ConpulseMaterialModule } from '../../material-module';
import { ConpulseModule } from '../../conpulse.module';
import { FileInputModule } from '../file-input/file-input.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { QuillModule } from 'ngx-quill';
import { I18NextModule } from 'angular-i18next';
import { UtilityMethodsService } from '../../services';
import { DynamicFormValidationChipModule } from '../dynamic-form-validation-chip/dynamic-form-validation-chip.module';

@NgModule({
  declarations: [CommonAppendixComponent],
  imports: [
    CommonModule,
    ConpulseMaterialModule,
    ConpulseModule,
    I18NextModule,
    DragDropModule,
    QuillModule.forRoot(),
    DynamicFormValidationChipModule,
    FileInputModule
  ],
  exports: [CommonAppendixComponent],
})
export class CommonAppendixModule {
  constructor(private readonly utilityMethod: UtilityMethodsService) {
    this.utilityMethod.prepareQuillModule();
  }
}
